<div class="error-default">
  <div class="container">
    <div class="title-container">
      <legend>
        <span class="main-icon evo ico-warning-pink"></span>
        <h2>{{errService.titulo}}</h2>
        <p>{{errService.subtitulo}}</p>
      </legend>
    </div>
    <div class="row btn-group">
      <button class="btn-common btn-common_fix_bold" (click)="handleButtonClick()">
        <span>{{errService.boton}}</span>
      </button>
    </div>
  </div>
</div>
<app-footer-center *ngIf="showFooter"></app-footer-center>