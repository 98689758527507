import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorService } from 'app/services/error.service';

@Component({
  selector: 'app-servicio-no-disponible',
  templateUrl: './servicio-no-disponible.component.html',
  styleUrls: ['./servicio-no-disponible.component.scss']
})
export class ServicioNoDisponibleComponent implements OnInit {
  constructor(public errService: ErrorService, private router: Router) { }

  public showFooter = true;
  
  ngOnInit(): void {
    this.errService.titulo = 'PRODUCTO NO DISPONIBLE';
    this.errService.subtitulo = 'Lo sentimos, este producto no está disponible para nuevas contrataciones.';
    this.errService.boton = 'VOLVER';
  }

  handleButtonClick() {
    window.location.href='https://www.evobanco.com/inversion/';
  }
}