import { SessionStorageService } from './../../../services/session-storage.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ErrorService } from 'app/services/error.service';
import { States } from 'app/collections/states-card';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { MyProductsService } from 'app/services/my-products.service';
import { HelperService } from 'app/services/helpers.service';
import { ModalService } from 'app/services/modal.service';
import { AnalyticsService } from 'app/services/analytics.service';
import { ACCOUNTS } from 'app/resources/account.model';
import { PersonaService } from 'app/services/persona.service';
import { DeviceService } from 'app/services/device.service';
import { environment } from 'environments/environment';
import { ConstantsService } from 'app/services/constants.service';


@Component({
  // tslint:disable-next-line: component-selector
  selector: 'view-investment-account',
  templateUrl: './view-investment-account.component.html',
  styleUrls: ['./view-investment-account.component.scss'],
  providers: [ModalService]
})
export class ViewInvestmentAccountComponent implements OnInit, AfterViewInit {

  public clientID;
  public loading;
  public showSecondStep = true;
  public cuentas: Array<any> = [];
  public cuentasCliente: any;
  public numRisks: number[] = [1, 2, 3, 4, 5, 6, 7];

  public viewForm: FormGroup;
  public infoMifid = false;
  public poliConflic = false;
  public poliOpti = false;
  public poliSalva = false;
  public poliIncen = false;
  public folleto = false;
  public condiciones: boolean = sessionStorage.getItem('condiciones') === 'true';
  public bannerRevisa = false;
  public hiddeNotification = true;
  public obj: Object;
  public currentPath: string;
  public step: number;

  public canContinue = false;

  public estado: string;
  public nameError = '';
  public showError = false;
  public nombreSegundoTitular;
  public mailCliente;
  public esFondoInteligente = sessionStorage.getItem('productoContratando') === '010' ? true : false;
  public actualizandoInfoContractual = false;
  public enviadoEstadoGestion = false;
  public validateSubmit = false;
  public isBmi = false;
  public isMobileOrTablet: boolean = this.deviceService.isMobileOrTablet();

  public modificacionOK = false;
  public hideBannerNotificacionOk = false;

  stateCurrent = States.enviada;
  currentProduct: any;
  fullName: string;
  address: string;
  ACCOUNTS = ACCOUNTS;

  modalPDF = 'document-modal';
  clasificacionMiFid = '';
  showNoCatDocuments = true;
  mostrarBannerMail = false;
  emailSegundoTitular: string = null;

  constructor(
    private router: Router,
    private helpers: HelperService,
    public myProductSrv: MyProductsService,
    private helpersService: HelperService,
    public modService: ModalService,
    private analytics: AnalyticsService,
    public errService: ErrorService,
    public personaSrv: PersonaService,
    private activatedRoute: ActivatedRoute,
    private deviceService: DeviceService,
    public sessionStorageSrv: SessionStorageService,
  ) {

    this.createForm();

    if (sessionStorage.getItem('docPrecontractual') === 'true') {
      this.marcarDocumentosGestion();
    }

    this.router.events.subscribe((url: any) => {
      if (url instanceof NavigationEnd) {
        this.currentPath = url.url;
        this.setSteps();
      }
    });
  }

  ngOnInit() {

    this.hideBannerNotificacionOk = JSON.parse(sessionStorage.getItem('notificacionOk'));
    this.modificacionOK = (sessionStorage.getItem('peticion-modificacion') === 'true');

    this.activatedRoute.queryParams.subscribe(params => {
      this.isFondoInteligente(params['codProductoContratado']);
      if(params['isSecondSigner']){
        sessionStorage.setItem('isSecondSigner',params['isSecondSigner']);
      }
    });

    if (sessionStorage.getItem('isBMI') === 'true') {
      this.isBmi = true;
    }
    this.updateTermsAccepted();

    this.emailSegundoTitular = sessionStorage.getItem('emailSegundoTitular');
    if (this.emailSegundoTitular) {
      this.mostrarBannerMail = true;
    }

    if (sessionStorage.getItem('clasificacionMiFidEnviada') !== 'true') {
      this.personaSrv.getCategorizacion().subscribe(data => {
        console.log('Categorizacion:', data);
        if (data.EE_O_Consulta.Respuesta.codigoClasificacionMifid) {
          this.clasificacionMiFid = data.EE_O_Consulta.Respuesta.codigoClasificacionMifid;
          this.showNoCatDocuments = false;
          sessionStorage.setItem('showNoCatDocuments', 'false');
          sessionStorage.setItem('clasificacionMiFid', data.EE_O_Consulta.Respuesta.codigoClasificacionMifid);
          const fechaFormatoSF = this.helpersService.formatDateDayMonthYear(new Date(data.EE_O_Consulta.Respuesta.fechaComunicacion));
          sessionStorage.setItem('fechaMiFid', fechaFormatoSF);
          sessionStorage.setItem('categorizacionAnterior', 'true');
          sessionStorage.setItem('clasificacionMiFidEnviada', 'true');
          this.updateCalificacionInversora(data.EE_O_Consulta.Respuesta.codigoClasificacionMifid, fechaFormatoSF, true);
        } else {
          sessionStorage.setItem('clasificacionMiFidEnviada', 'true');
          sessionStorage.removeItem('clasificacionMiFid');
          this.showNoCatDocuments = false;
          sessionStorage.setItem('showNoCatDocuments', 'false');
          this.clasificacionMiFid = '';
        }
      },
        error => {
          if (error.status === 400) {
            console.log('ERROR' + error.error.EE_O_Consulta);
            sessionStorage.removeItem('clasificacionMiFid');
            this.clasificacionMiFid = '';
            this.showNoCatDocuments = true;
            sessionStorage.setItem('showNoCatDocuments', 'true');
            sessionStorage.setItem('clasificacionMiFidEnviada', 'true');
          } else {
            sessionStorage.removeItem('clasificacionMiFidEnviada');
            this.errorInversora('SATELITE-C', 'Error servicio consulta categorización');
          }
        });

    }

  }

  ngAfterViewInit() {
    this.updateTermsAccepted();

    if (this.canContinue && this.folleto && this.condiciones && !sessionStorage.getItem('todoFirmado')) {
      sessionStorage.setItem('todoFirmado', 'true');
    } else {
      if(!this.infoMifid){
        this.triggerInvesmentNavData('Documentación');
      }
      sessionStorage.removeItem('todoFirmado');
    }

    //  Se desplaza el scroll hacia la posición anterior (si se ha avanazado a la ventana de documentación).
    const scrollTop = +sessionStorage.getItem('scrollTop');
    if (scrollTop) {
      window.scrollTo(0, scrollTop);
      document.body.scrollTop = scrollTop;
    }
  }

  submit() {

    sessionStorage.removeItem('scrollTop');

    this.loading = true;
    this.hiddeNotification = this.canContinue || this.enviadoEstadoGestion;
    if (sessionStorage.getItem('abandono')) {
      this.enviadoEstadoGestion = true;
    }
    this.validateSubmit = true;

    const activarSegundoTitular = sessionStorage.getItem('activarSegundoTitular');
    if (sessionStorage.getItem('productoContratando') === ConstantsService.AccountTypes.CuentaInversoraCorto.code && !activarSegundoTitular) {
      this.showError = true;
      this.nameError = 'preparadoFirmaCI';
    } else if (sessionStorage.getItem('productoContratando') === ConstantsService.AccountTypes.FondoInteligente.code && !activarSegundoTitular) {
      this.showError = true;
      this.nameError = 'preparadoFirmaFI';
    }

    if (this.canContinue && /* this.folleto && */ this.condiciones && this.enviadoEstadoGestion) {
      this.bannerRevisa = false;
      const idCaso = sessionStorage.getItem('casoSeleccionado');
      if (sessionStorage.getItem('isSecondSigner') === 'true') {
        // Objeto formado para SEGUNDO titular
        this.obj = {
          'estado': 'previa-firma-tit2',
          'idCaso': idCaso,
          'informacionMIFIDTit2': !this.showNoCatDocuments ? this.infoMifid : undefined,
          'packBienvenidaTit2': this.showNoCatDocuments ? this.infoMifid : undefined,
          //  "informacionMIFIDTit2": this.infoMifid,
          // "politicaGestion": this.poliGestion,
          'politicaConflictosTit2': this.poliConflic,
          'politicaSalvaguardadaTit2': this.poliSalva,
          'politicaOptimaTit2': this.poliOpti,
          'politicaIncentivosTit2': this.poliIncen,
          'folletoTarifasFrecuentesTit2': this.folleto,
          'condicionesLegalesTit2': this.condiciones
        };
      } else {
        // Objeto formado para PRIMER titular
        if (sessionStorage.getItem('activarSegundoTitular') === 'true') {
          this.estado = 'login-tit2';
        } else {
          this.estado = 'previa-firma-tit1';
        }
        this.obj = {
          'estado': this.estado,
          'idCaso': idCaso,
          'informacionMIFID': !this.showNoCatDocuments ? this.infoMifid : undefined,
          'packBienvenida': this.showNoCatDocuments ? this.infoMifid : undefined,
          //  "informacionMIFID": this.infoMifid,
          // "politicaGestion": this.poliGestion,
          'politicaConflictos': this.poliConflic,
          'politicaSalvaguardada': this.poliSalva,
          'politicaOptima': this.poliOpti,
          'politicaIncentivos': this.poliIncen,
          'folletoTarifasFrecuentes': this.folleto,
          'condicionesLegales': this.condiciones
        };
      }
      this.personaSrv.modificarCuenta(this.obj).subscribe(data => {
        this.showError = false;
        this.nameError = 'ocultarErrores';
        const activarSegundoTitularAux = sessionStorage.getItem('activarSegundoTitular');
        if (data.codigo === 'OK' && !activarSegundoTitularAux) {
          // Enviar a firmar logalty.
          // Mostramos pantallas de carga de firma de logalty.
          this.helpers.navigateTo('logalty-ampliacion');
        } else if (data.codigo === 'OK' && activarSegundoTitularAux) {
          // pantalla de envio de mail a segundo titular
          this.nombreSegundoTitular = sessionStorage.getItem('emailSegundoTitular');
          this.mailCliente = JSON.parse(sessionStorage.getItem('userInfo')).email || JSON.parse(sessionStorage.getItem('userInfo')).datosPersonales.email;
          this.showError = true;
          this.nameError = 'activarSegundoTitular';
          this.triggerAnalyticsNavData();
          this.loading = false;
        } else {
          console.log('Controlar error viewInvestment Submit put');
          this.loading = false;
        }

      }, error => {
        this.loading = false;
        this.showError = false;
        this.nameError = 'ocultarErrores';
        this.checkErrorStatusAndGoToError(error, 'SATELITE-C');
      });


    } else {
      console.log('formulario no válido');
      // Mostrar banner
      this.bannerRevisa = true;
      this.hiddeNotification = false
      this.showError = false;
      this.nameError = 'ocultarErrores';
      this.loading = false;
    }


  }
    
  private triggerAnalyticsNavData() {
    if (sessionStorage.getItem("productoContratando") == ConstantsService.AccountTypes.CuentaInversora.code) {
      this.triggerInvesmentNavData('Confirmación cuenta');
    }
    else if (sessionStorage.getItem("productoContratando") == ConstantsService.AccountTypes.FondoInteligente.code) {
      this.triggerInvesmentNavData('Confirmación fondo');
    }
  }

  botonVolver() {
    if (sessionStorage.getItem('isSecondSigner') === 'true') {
      this.updateInversora('modificacion-datos-tit2');
      this.goToConsola();
    } else {
      sessionStorage.setItem('operacion-inversora', 'operacion-inversora-cuenta-cargo');
      this.updateInversora('cuenta-cargo');
      this.helpers.navigateTo('configuracion-cuenta-inversora');
    }
  }

  updateInversora(estado) {
    const idCaso = sessionStorage.getItem('casoSeleccionado');
    this.obj = {
      'estado': estado,
      'idCaso': idCaso
    }
    this.personaSrv.modificarCuenta(this.obj).subscribe(data => {
      if (data.codigo === 'OK') {
        this.showError = false;
      } else if (data.codigo === '01' || data.codigo === '02' || data.codigo === '03' || data.codigo === '04') {
        this.showError = false;
      } else {
        this.showError = false;
      }
    }, _error => {
      this.checkErrorStatusAndGoToError(_error, 'SATELITE-C');
    });
  }

  createForm() {
    this.viewForm = new FormGroup({
      infoMifid: new FormControl('', Validators.required),
      // poliGestion: new FormControl('', Validators.required),
      poliConflic: new FormControl('', Validators.required),
      poliOpti: new FormControl('', Validators.required),
      poliSalva: new FormControl('', Validators.required),
      poliIncen: new FormControl('', Validators.required),
      folleto: new FormControl('', Validators.required),
      condiciones: new FormControl('', Validators.required),
      consentimiento: new FormControl('', Validators.required)
    });
  }

  hideNotification() {
    this.hiddeNotification = true;
  }

  setSteps() {
    if (this.currentPath) {
      if (this.currentPath.indexOf('/mi-perfil') !== -1) {
        this.step = 1;
      } else if (this.currentPath.indexOf('/configuracion-cuenta-inversora') !== -1) {
        this.step = 2;
      } else if (this.currentPath.indexOf('/visor-cuenta-inversora') !== -1) {
        this.step = 3;
      } else if (this.currentPath.indexOf('/final-cuenta-inversora') !== -1) {
        this.step = 4;
      }
    } else if (!this.step) {
      this.step = 1;
    }
  }

  /**
   * Re-open an already acepted document
   * @param document
   */
  reviewDocument(document) {
    //  Se almacena el scroll actual.
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    sessionStorage.setItem('scrollTop', scrollTop.toString());

    sessionStorage.setItem('currentDocument', document);
    this.helpers.navigateTo('documentacion-cuenta-inversora');
  }

  openDocument(documento) {

    sessionStorage.setItem('currentDocument', documento);
    if (sessionStorage.getItem(documento) === 'true') {
      sessionStorage.removeItem(documento);
      this.updateTermsAccepted();
    } else {
      //  Se almacena el scroll actual.
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      sessionStorage.setItem('scrollTop', scrollTop.toString());
      this.helpers.navigateTo('documentacion-cuenta-inversora');
    }
  }

  updateTermsAccepted() {
    const docPrecontractual = !!sessionStorage.getItem('docPrecontractual');
    if (docPrecontractual) {
      this.marcarDocumentosGestion();
    }
    const clasificacionMiFid = !!sessionStorage.getItem('clasificacionMiFid');
    this.showNoCatDocuments = !clasificacionMiFid;
    if (this.showNoCatDocuments) {
      sessionStorage.setItem('showNoCatDocuments', 'true');
    } else {
      sessionStorage.setItem('showNoCatDocuments', 'false');
    }

    this.infoMifid = sessionStorage.getItem('infoMifid') === 'true';
    // this.poliGestion = (sessionStorage.getItem("poliGestion") === 'true');
    // this.poliConflic = (sessionStorage.getItem('poliConflic') === 'true');
    // this.poliOpti = (sessionStorage.getItem('poliOpti') === 'true');
    // this.poliSalva = (sessionStorage.getItem('poliSalva') === 'true');
    // this.poliIncen = (sessionStorage.getItem('poliIncen') === 'true');
    // this.folleto = sessionStorage.getItem('folleto') === 'true';
    this.condiciones = sessionStorage.getItem('condiciones') === 'true';

    const updatePrecontractual = this.infoMifid;// && this.poliConflic && this.poliOpti && this.poliSalva && this.poliIncen && this.folleto;

    if (!updatePrecontractual) {
      this.actualizandoInfoContractual = false;
    }

    // Si ha marcado los 5 primeros checkboxes y no ha sido ya informado previamente llamar al update y cambiar el estado
    if (!docPrecontractual && updatePrecontractual && !this.actualizandoInfoContractual) {
      this.gestionPrecontractual();
    }

    this.canContinue = this.infoMifid; //&& this.poliConflic && this.poliOpti && this.poliSalva && this.poliIncen;

    if (sessionStorage.getItem('enviadoEstadoGestion')) {
      this.enviadoEstadoGestion = true;
    }

  }


  updateCalificacionInversora(clasificacionMiFid, fechaMiFid, categorizacionAnterior) {

    const idCaso = sessionStorage.getItem('casoSeleccionado');

    if (sessionStorage.getItem('isSecondSigner') === 'true') {
      this.obj = {
        'idCaso': idCaso,
        'clasificacionMiFidSegundoTitular': clasificacionMiFid,
        'fechaMiFidSegundoTitular': fechaMiFid,
        'categorizacionAnteriorSegundoTitular': true
      };
    } else {
      this.obj = {
        'idCaso': idCaso,
        'clasificacionMiFid': clasificacionMiFid,
        'fechaMiFid': fechaMiFid,
        'categorizacionAnterior': true
      };
    }

    this.personaSrv.modificarCuenta(this.obj).subscribe(data => {
      if (data.codigo === 'OK') {
        this.showError = false;
        console.log('Cuenta modificada');
        sessionStorage.setItem('clasificacionMiFidEnviada', 'true');
      } else if (data.codigo === '01' || data.codigo === '02' || data.codigo === '03' || data.codigo === '04') {
        this.showError = false;
      } else {
        this.showError = false;
      }
    }, error => {
      sessionStorage.removeItem('clasificacionMiFidEnviada');
      this.checkErrorStatusAndGoToError(error, 'SATELITE-C');
    });
  }

  gestionPrecontractual() {
    this.loading = true;
    this.actualizandoInfoContractual = true;
    const idCaso = sessionStorage.getItem('casoSeleccionado');

    if (sessionStorage.getItem('isSecondSigner') === 'true') {
      this.obj = {
        'estado': 'gestion-info-precontractual-tit2',
        'idCaso': idCaso,
        //  "informacionMIFIDTit2": true,
        'informacionMIFIDTit2': !this.showNoCatDocuments ? true : undefined,
        'packBienvenidaTit2': this.showNoCatDocuments ? true : undefined,
        'politicaConflictosTit2': true,
        'politicaSalvaguardadaTit2': true,
        'politicaOptimaTit2': true,
        'politicaIncentivosTit2': true,
        'folletoTarifasFrecuentesTit2': true
      };
    } else {
      this.obj = {
        'estado': 'gestion-info-precontractual-tit1',
        'idCaso': idCaso,
        //  "informacionMIFID": true,
        'informacionMIFID': !this.showNoCatDocuments ? true : undefined,
        'packBienvenida': this.showNoCatDocuments ? true : undefined,
        'politicaConflictos': true,
        'politicaSalvaguardada': true,
        'politicaOptima': true,
        'politicaIncentivos': true,
        'folletoTarifasFrecuentes': true
      };
    }

    this.personaSrv.modificarCuenta(this.obj).subscribe(data => {
      if (sessionStorage.getItem('isSecondSigner') === 'true') {
        sessionStorage.setItem('docPrecontractual', 'true');
      }
      this.enviadoEstadoGestion = true;
      sessionStorage.setItem('enviadoEstadoGestion', 'true');
      this.loading = false;
    }, error => {
      // Redirige al 404 de Modificación de datos
      this.actualizandoInfoContractual = false;
      this.loading = false;
      sessionStorage.removeItem('docPrecontractual');
      this.goToError('SATELITE-C');
    });

  }

  marcarDocumentosGestion() {
    sessionStorage.setItem('infoMifid', 'true');
    // sessionStorage.setItem('poliConflic', 'true');
    // sessionStorage.setItem('poliOpti', 'true');
    // sessionStorage.setItem('poliSalva', 'true');
    // sessionStorage.setItem('poliIncen', 'true');

    // Folleto tarifas frecuentes.
    // const data = JSON.parse(sessionStorage.getItem('getCaso'));

    // if (sessionStorage.getItem('abandono') && data && data.infoCaso && data.infoCaso.datosPrimerTitular &&
    //   data.infoCaso.datosPrimerTitular.folletoTarifasFrecuentes === true) {
    //   sessionStorage.setItem('folleto', 'true');
    // }

  }

  triggerInvesmentNavData(pageName) {
    const isSecondSigner = sessionStorage.getItem('isSecondSigner') === 'true';
    const clientId = JSON.parse(sessionStorage.getItem('userInfo')).id_salesforce ? JSON.parse(sessionStorage.getItem('userInfo')).id_salesforce : '';
    this.analytics.triggerInvestmentDataNavDataNew(pageName, clientId);
  }


  errorInversora(errorMessage, errorDescription) {
    const idCaso = sessionStorage.getItem('casoSeleccionado');
    this.obj = {
      'estado': this.estado,
      'idCaso': idCaso,
      'description': errorDescription
    };
    this.personaSrv.modificarCuenta(this.obj).subscribe();
    this.goToError(errorMessage);
  }

  goToError(errorMessage) {
    this.errService.navToErrorModData(errorMessage, 'ENTENDIDO', 'bancaelectronica');
  }

  isFondoInteligente(codProducto) {

    if (sessionStorage.getItem('operacion-inversora') === 'fondo-inteligente-nickname' ||
      sessionStorage.getItem('operacion-inversora') === 'fondo-inteligente' ||
      codProducto === ConstantsService.AccountTypes.FondoInteligente.code) {
      this.esFondoInteligente = true;
    }

  }

  hideBannerNotifyOk() {
    this.hideBannerNotificacionOk = true;
    // metemos en sesion si ha cerrado el baner.
    sessionStorage.setItem('notificacionOk', 'true');
  }

  goToConsola() {
    let redirectUrl = `${environment.endpoint}#/visor-cuenta-inversora`;
    let codProductoContratado = this.esFondoInteligente ? ConstantsService.AccountTypes.FondoInteligente.code : ConstantsService.AccountTypes.CuentaInversora.code;
    this.helpers.goToConsola(codProductoContratado, sessionStorage.getItem('casoSeleccionado'), redirectUrl);
  }

  checkErrorStatusAndGoToError(error, errorMessage){
    if(error && error.status && error.status.toString() === '401'){
      this.errService.isStatus401 = true;
    }
    if(!this.errService.isStatus401 && errorMessage !== 'BATCH'){
      this.triggerInvesmentNavData('Error - 404');
    }
    this.goToError(errorMessage);
  }
}
