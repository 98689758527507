import { Injectable, Inject } from '@angular/core';

import { Observable } from 'rxjs';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/delay';

import { DeviceService } from './device.service';

import { ApiUserService } from './api/api.user.service';
import { LoginService } from './login.service';
import { User } from '../collections/user';
import { GetContractResponse } from '../collections/get-contract';

@Injectable()
export class AuthUserService {
  constructor (
    private api: ApiUserService,
    private loginSrv:LoginService,
    public deviceService: DeviceService) {}

  getUserData(idExterno?):Observable<User> {
    if(!idExterno){
      const loginData = this.loginSrv.loginData.userinfo.idExterno;
      return this.api.getUserData({"idExterno" : loginData});
    }
    return this.api.getUserData({"idExterno" : idExterno});
  }

  getUserDataToken(idExterno):Observable<User> {
    return this.api.getUserData({"idExterno" : idExterno});
  }


  getMyContract(idProducto): Observable<GetContractResponse>  {
    return this.api.getMyContract(idProducto);
  }

  getClientData(): Observable<User>  {
    return this.api.getClientData();
  }

  getConsoleRedirection(params): Observable<any> {
    return this.api.getConsoleRedirection(params);
  }

  get isLoggedIn():boolean{
    return this.loginSrv.isLoggedIn;
  }
}
